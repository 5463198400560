import './App.css';
import { useState } from 'react';

function ColorBlock({color}) {
  return (
    <div className="color-block" style={{background:color, width:"50vh", height:"25vh", margin:"10px", borderRadius:"25px"}}></div>
  )
}

function Slider(props) {
  // const [value, setValue] = useState(state[name]);

  function handleChange(event) {
    // setValue(event.target.value);
    props.change(props.name, parseInt(event.target.value))
  }

  return (
    <div className="slider">
      <input type="range" min="0" max="255" value={props.state[props.name]} onChange={handleChange} className={props.name} id="myRange"></input>
    </div>
  )
}


function App() {

  const [state, setState] = useState({
    gameEndMessage: "",
    percentage: "",
    Rvalue: Math.floor(Math.random() * 255),
    Gvalue: Math.floor(Math.random() * 255),
    Bvalue: Math.floor(Math.random() * 255),
    targetRvalue: Math.floor(Math.random() * 255),
    targetGvalue: Math.floor(Math.random() * 255),
    targetBvalue: Math.floor(Math.random() * 255)
  });

  function getRandColor() {
    setState(prevState =>
      ({
        ...prevState,
        percentage: "",
        targetRvalue: Math.floor(Math.random() * 255),
        targetGvalue: Math.floor(Math.random() * 255),
        targetBvalue: Math.floor(Math.random() * 255)
      }
    ))
  }

  function changeValue(name, value) {
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  function getAccuracy() {
    setState(prevState => ({
      ...prevState,
      percentage: Math.floor(100 - (Math.abs(state.Rvalue - state.targetRvalue) + Math.abs(state.Gvalue - state.targetGvalue) + Math.abs(state.Bvalue - state.targetBvalue))/7.65) + "%"
    }));
  }

  // function checkForWin() {
  //   if (
  //     state.Rvalue > state.targetRvalue-10 && state.Rvalue < state.targetRvalue+10  && 
  //     state.Gvalue > state.targetGvalue-10 && state.Gvalue < state.targetGvalue+10  && 
  //     state.Bvalue > state.targetBvalue-10 && state.Bvalue < state.targetBvalue+10 ) {
  //     setState(prevState => ({
  //       ...prevState,
  //       gameEndMessage: "You win!"
  //     }));
  //   }
  //   else {
  //     setState(prevState => ({
  //       ...prevState,
  //       gameEndMessage: "Try again!"
  //     }));
  //   }
  // }

  return (
    <div className="App">
      <header className="App-header">
        
        <h3>RGB Color Match</h3>

        <ColorBlock color={"rgb(" + state.targetRvalue + "," + state.targetGvalue + "," + state.targetBvalue + ")"}/>
        <ColorBlock color={"rgb(" + state.Rvalue + "," + state.Gvalue + "," + state.Bvalue + ")"}/>

          <Slider className="redSlider" change = {changeValue} state = {state} name="Rvalue"/>

          <Slider className="greenSlider" change = {changeValue} state = {state} name="Gvalue"/>

          <Slider className="blueSlider" change = {changeValue} state = {state} name="Bvalue"/>

        <div className="buttonContainer">
          <button onClick={() => getAccuracy()}>Guess</button>
          <button onClick={() => getRandColor()}>New Color</button>
        </div>

        {state.percentage}
        
        </header>
    </div>
  );
}

export default App;
